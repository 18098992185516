
import { gpf } from "@/utils/global-functions";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  nextTick,
  ref,
  watch,
} from "vue";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const LabelWithColon = defineAsyncComponent(
  () => import(`@/components/label/WithColon.vue`)
);

const servicesV1AdminLearningCategories = async () =>
  import(`@/services/v1/admin/learning/categories`);

export default defineComponent({
  components: {
    ModalTemplate,
    LabelWithColon,
  },
  props: {
    showDetailTopic: {
      type: Boolean,
      default: false,
    },
    topicId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const modalId = "modal-detail-topic";
    const topicId = computed(() => props.topicId);
    const detailCategory = ref({} as any);
    const loading = ref(false as boolean);

    const getDetailTopic = async () => {
      if (!topicId.value) return;

      const {
        getAdminLearningCategoryById,
      } = await servicesV1AdminLearningCategories();

      gpf.gLoading.show();
      loading.value = true;

      try {
        const hit = await getAdminLearningCategoryById(
          topicId.value.toString()
        );
        const resData = hit.response;

        console.log("resData getDetailTopic ", resData);

        await nextTick();

        detailCategory.value = resData;
      } catch (err: any) {
        console.log("err response getDetailTopic ", err, err?.response);

        gpf.handleEds(err);
      } finally {
        gpf.gLoading.hide();
        loading.value = false;
      }
    };

    const hideDetailTopic = () => {
      emit("update:show-detail-topic", false);
      emit("update:topic-id", 0);
    };

    watch(
      () => props.showDetailTopic,
      (newVal) => {
        if (newVal) gpf.showModal(`#${modalId}`);
        gpf.onHideModal(`#${modalId}`, hideDetailTopic);
        getDetailTopic();
      }
    );

    const onCloseClick = () => {
      gpf.hideModal(`#${modalId}`);
    };

    return {
      modalId,
      detailCategory,
      gpf,
      loading,
      onCloseClick,
    };
  },
});
